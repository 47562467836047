import React, { Component } from "react";
import "./ChoiceComp.scss";

class PaintCard extends Component {
  render() {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className={this.props.locationIsHidden ? 'hidden': ''}>
          { this.props.locationchoices }
        </div>
        <div className={this.props.categoryIsHidden ? 'hidden': ''}>
          { this.props.categorychoices }
        </div>
        <div className={this.props.systemIsHidden ? 'hidden': ''}>
          { this.props.systemchoices }
        </div>
        <div className={this.props.propertyIsHidden ? 'hidden': ''}>
          { this.props.propertychoices }
        </div>
      </div>
    );
  }

}

export default PaintCard;
