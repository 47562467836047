import React, { Component } from "react";
import "./HeaderComp.scss";
import logo from "../img/logo.png";

class HeaderComp extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Kaas" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="https://drostcoatings.nl/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Assortimentswijzer
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://drostcoatings.nl/nieuws">
                  Nieuws
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://drostcoatings.nl/referenties"
                >
                  Referenties
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://drostcoatings.nl/verkooppunten"
                >
                  Verkooppunten
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="https://drostcoatings.nl/over-drost"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Over Drost
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    className="dropdown-item"
                    href="https://drostcoatings.nl/geschiedenis"
                  >
                    Geschiedenis
                  </a>
                  <a
                    className="dropdown-item"
                    href="https://drostcoatings.nl/duurzaamheid"
                  >
                    Duurzaamheid
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://drostcoatings.nl/contact"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default HeaderComp;
