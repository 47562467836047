import React from "react";
import "./App.scss";
import HeaderComp from "./components/HeaderComp";
import ChoiceComp from "./components/ChoiceComp";
import PaintCard from "./components/PaintCard";
import loader from "./img/loader.gif";
import axios from "axios";

let productlist;
let locationchoices;
let categorychoices;
let systemchoices;
let propertychoices;
let newStep;

class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        products: [],
        locations: [],
        categories: [],
        systems: [],
        properties: [],

        selectedLocation: '',
        selectedCategory: '',
        selectedSystem: '',
        selectedProperty: '',

        locationIsHidden: false,
        categoryIsHidden: true,
        systemIsHidden: true,
        propertyIsHidden: true,

        currentStep: 0,
        buttonState: false,

        loading: true
      };
    }
    render() {
        return (
          <div className="App">
            <header className="App-header">
              <HeaderComp />
            </header>
            <div className="container-fluid my-5">
              <div className="row w-100 mb-5">
                <ul className="progressbar">
                    <li className={ !this.state.locationIsHidden ? 'active': '' }>Kies een locatie</li>
                    <li className={ !this.state.categoryIsHidden ? 'active': '' }>Kies een categorie</li>
                    <li className={ !this.state.systemIsHidden ? 'active': '' }>Kies een systeem</li>
                    <li className={ !this.state.propertyIsHidden ? 'active': '' }>Kies een component</li>
                    <li className={ this.state.propertyIsHidden && this.state.systemIsHidden && this.state.categoryIsHidden && this.state.locationIsHidden ? 'active': '' }>Het perfect blik!</li>
                </ul>
              </div>
              <ChoiceComp locationchoices={locationchoices} locationIsHidden={this.state.locationIsHidden} categorychoices={categorychoices} categoryIsHidden={this.state.categoryIsHidden} systemchoices={systemchoices} systemIsHidden={this.state.systemIsHidden} propertychoices={propertychoices} propertyIsHidden={this.state.propertyIsHidden}/>
            </div>
            <div className="container-fluid my-5">
              <div className="row">
                {this.state.loading ? '' : <BackButton stepBack={this.stepBack} buttonState={this.state.buttonState}/>}
                {this.state.loading ? <LoadingSpinner /> : <PaintCard products={productlist}/>}
                {this.state.loading ? '' : <BackButton stepBack={this.stepBack} buttonState={this.state.buttonState}/>}
              </div>
            </div>
          </div>
      );
  }

  componentWillMount() {
    this.initiateLocations();
  }

  initiateLocations = () => {

    this.setState({
      locationIsHidden: false,
      categoryIsHidden: true,
      systemIsHidden: true,
      propertyIsHidden: true,
      buttonState: false,
      loading: true
    });

    axios.get(`https://drostcoatings.nl/api/filter`).then(res => {

      res.data.products.map(obj =>
        this.setState({ products: Object.values(obj) })
      );

      this.setState({
        loading: false,
        products: res.data.products,
        locations: res.data.locations
      });

      locationchoices = Object.keys(this.state.locations).map(key => (
        <button className="btn btn-purple mr-3 mb-3" key={key} value={this.state.locations[key].id} onClick={this.handleLocationChange}>{this.state.locations[key].name}</button>
      ));

      productlist = Object.keys(this.state.products).map(key => (
        <div key={key} className="col-6 col-md-4 col-lg-3 col-xl-2 blik">
          <a href={`https://drostcoatings.nl/assortiment/${this.state.products[key].slug}`}>
            <div className="blik-inner-wrapper">
              <img src={`${this.state.products[key].cover_image}`} alt={this.state.products[key].name} />
              <h4 className="d-flex align-items-center">{this.state.products[key].name}</h4>
            </div>
          </a>
        </div>
      ));

      newStep = this.state.currentStep + 1;
      this.setState({
        productlist: productlist,
        currentStep: newStep
      });
    });
  }

  handleLocationChange = (e) => {
      let event = null;
      if(e){
          event = e.target.value;
      this.setState({
        selectedLocation: e.target.value
      })
    } else{
      event = this.state.selectedLocation;
    }
    this.setState({
      locationIsHidden: true,
      categoryIsHidden: false,
      systemIsHidden: true,
      propertyIsHidden: true,
      buttonState: true,
      loading: true
    }, function() {
      axios.get('https://drostcoatings.nl/api/filter?location=' + this.state.selectedLocation, {
        selectedLocation: this.state.selectedLocation
      })
      .then(res => {

          if (event === 2){
              res.data.products.map(obj =>
                  this.setState({ products: Object.values(obj) })
              );

              this.setState({
                  products: res.data.products,
                  categories: res.data.categories,
                  loading:false
              });

              let filteredCategories = this.state.categories.filter((category) => {
                  return category.slug !== 'meubilair-keukens' && category.slug !== 'overig';
              });

              categorychoices = Object.keys(filteredCategories).map(key => (
                  <button className="btn btn-purple mr-3 mb-3" key={key} value={filteredCategories[key].id}
                          onClick={this.handleCategoryChange}>{filteredCategories[key].name}</button>
              ));

              productlist = Object.keys(this.state.products).map(key => (
                  <div key={key} className="col-6 col-md-4 col-lg-3 col-xl-2 blik">
                      <a href={`https://drostcoatings.nl/assortiment/${this.state.products[key].slug}`}>
                          <div className="blik-inner-wrapper">
                              <img src={`${this.state.products[key].cover_image}`} alt={this.state.products[key].name} />
                              <h4 className="d-flex align-items-center">{this.state.products[key].name}</h4>
                          </div>
                      </a>
                  </div>
              ));

              newStep = this.state.currentStep + 1;
              this.setState({
                  productlist: productlist,
                  currentStep: newStep
              });
          } else {
              res.data.products.map(obj =>
                  this.setState({ products: Object.values(obj) })
              );

              this.setState({
                  products: res.data.products,
                  categories: res.data.categories,
                  loading:false
              });

              categorychoices = Object.keys(this.state.categories).map(key => (
                  <button className="btn btn-purple mr-3 mb-3" key={key} value={this.state.categories[key].id} onClick={this.handleCategoryChange}>{this.state.categories[key].name}</button>
              ));

              productlist = Object.keys(this.state.products).map(key => (
                  <div key={key} className="col-6 col-md-4 col-lg-3 col-xl-2 blik">
                      <a href={`https://drostcoatings.nl/assortiment/${this.state.products[key].slug}`}>
                          <div className="blik-inner-wrapper">
                              <img src={`${this.state.products[key].cover_image}`} alt={this.state.products[key].name} />
                              <h4 className="d-flex align-items-center">{this.state.products[key].name}</h4>
                          </div>
                      </a>
                  </div>
              ));

              newStep = this.state.currentStep + 1;
              this.setState({
                  productlist: productlist,
                  currentStep: newStep
              });
          }

      })
    })

  }

  handleCategoryChange = (e) => {
    if(e){
      this.setState({
        selectedCategory: e.target.value
      })
    } else{

    }
    this.setState({
      locationIsHidden: true,
      categoryIsHidden: true,
      systemIsHidden: false,
      propertyIsHidden: true,
      buttonState: true,
      loading: true
    }, function() {
      axios.get('https://drostcoatings.nl/api/filter?location=' + this.state.selectedLocation + '&category=' + this.state.selectedCategory, {
        selectedCategory: this.state.selectedCategory
      })
      .then(res => {

          res.data.products.map(obj =>
              this.setState({ products: Object.values(obj) })
          );

          this.setState({
              products: res.data.products,
              systems: res.data.systems,
              loading: false
          });

          systemchoices = Object.keys(this.state.systems).map(key => (
              <button className="btn btn-purple mr-3 mb-3" key={key} value={this.state.systems[key].id} onClick={this.handleSystemChange}>{this.state.systems[key].name}</button>
          ));

          productlist = Object.keys(this.state.products).map(key => (
              <div key={key} className="col-6 col-md-4 col-lg-3 col-xl-2 blik">
                  <a href={`https://drostcoatings.nl/assortiment/${this.state.products[key].slug}`}>
                      <div className="blik-inner-wrapper">
                          <img src={`${this.state.products[key].cover_image}`} alt={this.state.products[key].name} />
                          <h4 className="d-flex align-items-center">{this.state.products[key].name}</h4>
                      </div>
                  </a>
              </div>
          ));

          newStep = this.state.currentStep + 1;
          this.setState({
              productlist: productlist,
              currentStep: newStep
          });

      })
    });

  }

  handleSystemChange = (e) => {
    if(e){
      this.setState({
        selectedSystem: e.target.value
      })
    } else{

    }
    this.setState({
      locationIsHidden: true,
      categoryIsHidden: true,
      systemIsHidden: true,
      propertyIsHidden: false,
      buttonState: true,
      loading: true
    }, function() {
      axios.get('https://drostcoatings.nl/api/filter?location=' + this.state.selectedLocation + '&category=' + this.state.selectedCategory + '&system=' + this.state.selectedSystem, {
        selectedCategory: this.state.selectedCategory,
      })
      .then(res => {

          res.data.products.map(obj =>
            this.setState({ products: Object.values(obj) })
          );

          this.setState({
            products: res.data.products,
            properties: res.data.properties,
            loading: false
          });

          propertychoices = Object.keys(this.state.properties).map(key => (
            <button className="btn btn-purple mr-3 mb-3" key={key} value={this.state.properties[key].id} onClick={this.handlePropertyChange}>{this.state.properties[key].name}</button>
          ));

          productlist = Object.keys(this.state.products).map(key => (
              <div key={key} className="col-6 col-md-4 col-lg-3 col-xl-2 blik">
                <a href={`https://drostcoatings.nl/assortiment/${this.state.products[key].slug}`}>
                  <div className="blik-inner-wrapper">
                    <img src={`${this.state.products[key].cover_image}`} alt={this.state.products[key].name} />
                    <h4 className="d-flex align-items-center">{this.state.products[key].name}</h4>
                  </div>
                </a>
              </div>
          ));

          newStep = this.state.currentStep + 1;
          this.setState({
            productlist: productlist,
            currentStep: newStep
          });

      })
    });

  }

  handlePropertyChange = (e) => {

    if(e){
      this.setState({
        selectedProperty: e.target.value
      })
    } else{

    }
    this.setState({
      propertyIsHidden: true,
      buttonState: true,
      loading: true
    }, function() {
      axios.get('https://drostcoatings.nl/api/filter?location=' + this.state.selectedLocation + '&category=' + this.state.selectedCategory + '&system=' + this.state.selectedSystem + '&property=' + this.state.selectedProperty, {
        selectedCategory: this.state.selectedCategory,
      })
      .then(res => {

          res.data.products.map(obj =>
            this.setState({ products: Object.values(obj) })
          );

          this.setState({
            products: res.data.products,
            loading: false
          });

          productlist = Object.keys(this.state.products).map(key => (
            <div key={key} className="col-6 col-md-4 col-lg-3 col-xl-2 blik">
              <a href={`https://drostcoatings.nl/assortiment/${this.state.products[key].slug}`}>
                <div className="blik-inner-wrapper">
                  <img src={`${this.state.products[key].cover_image}`} alt={this.state.products[key].name} />
                  <h4 className="d-flex align-items-center">{this.state.products[key].name}</h4>
                </div>
              </a>
            </div>
          ));

          newStep = this.state.currentStep + 1;
          this.setState({
            productlist: productlist,
            currentStep: newStep
          });

      })
    });

  }

  stepBack = (e) => {

    e.preventDefault();

    if(this.state.currentStep === 2){
      this.initiateLocations();
    }
    if(this.state.currentStep === 3){
      this.handleLocationChange();
    }
    if(this.state.currentStep === 4){
      this.handleCategoryChange();
    }
    if(this.state.currentStep === 5){
      this.handleSystemChange();
    }

    newStep = this.state.currentStep - 2;

    this.setState({
      currentStep: newStep
    });

  }


}

class LoadingSpinner extends React.Component {
  render() {
    return(
      <div className="loader-wrapper"><img src={loader} alt="loader" /></div>
    )
  }
}

class BackButton extends React.Component {
  render(){
    return (
      <button className="btn-back ml-5 my-5" href="#" onClick={this.props.stepBack} disabled={!this.props.buttonState}>Vorige</button>
    )
  }

}

export default App;
