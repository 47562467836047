import React from "react";
import "./PaintCard.scss";

class PaintCard extends React.Component {

  render() {
      return (
        <div className="col-12">
            {this.props.products}
        </div>
      );
  }

}

export default PaintCard;
